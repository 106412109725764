<template>
  <loading :loading='loading' />
</template>

<script>
import {
  ref,
  reactive,
  onUpdated,
  onMounted,
  getCurrentInstance,
  computed,
} from "@vue/composition-api";
import { useRouter } from '@core/utils'
import api from "@/services";
import Loading from '@/components/general/Loading';

export default {
  components: { Loading },
    setup() {
        const { route } = useRouter();
        const vm = getCurrentInstance().proxy;
        const loading = ref(false);

        const formData = reactive({
            token: null
        });

        const handleValidationInvitation = async () => {
            try {
                loading.value = true;
                await api.userAcceptInviteProjects(formData);
                vm.$alert("", null, "success");

            } catch (error) {
                vm.$alert("", null, "error");
            } finally {
                loading.value = false;
                vm.$router.push("/")
            }
        }

        onMounted(() => {
            const { token } = route.value.query;

            if(token) {
                formData.token = token;
                handleValidationInvitation()

            } else vm.$router.push("/");

        });

        return {
            formData,
            loading
        }
    }
}
</script>

<style>

</style>